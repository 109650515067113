@use "abstracts/sizes";
@use "abstracts/colors";

@mixin gradient-font {
    background: linear-gradient(colors.$orange, colors.$orange, colors.$yellow);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.subpage {
    #leistungen {
        position: relative;
        background-size: cover;
        background-position: center;
        background-color: rgba(0, 0, 0, 0.7);
        background-blend-mode: darken;
        .row {
            align-items: start;
        }
        padding: calc(sizes.$large-spacing * 2) 0;
        h2 {
            color: colors.$orange
        }
        ul {
            list-style: none;
            padding: 0;
            margin-bottom: sizes.$text-padding;
            li {
                $li-space: 8px;
                $li-bg-color: rgba(100, 100, 100, 0.5);
                margin-bottom: calc($li-space * 2);
                display: flex;
                span {
                    background-color: $li-bg-color;
                    padding: $li-space calc($li-space * 2);
                    &:first-child {
                        width: 30%;
                        color: colors.$yellow;
                        border-radius: sizes.$border-radius sizes.$small-border-radius sizes.$small-border-radius sizes.$border-radius;
                    }
                    &:nth-child(2){
                        width: 25%;
                        border-radius: sizes.$small-border-radius;
                        margin: 0 calc($li-space / 2);
                    }
                    &:last-child {
                        width: 45%;
                        border-radius: sizes.$small-border-radius sizes.$border-radius sizes.$border-radius sizes.$small-border-radius;
                    }
                    &:only-child {
                        color: white;
                        width: 100%;
                        border-radius: sizes.$border-radius;
                    }
                }

            }
        }
    }
}