@use "bootstrap";
@use "abstracts/animations";
@use "abstracts/colors";
@use "abstracts/sizes";
@use "abstracts/fonts";
@use "navbar";


:root {
    color-scheme: dark;
}

html, body{
    background-color: colors.$base-color;
    color:#fff;
    font-family: "Roboto", sans-serif;
    overflow-x: hidden;
}


body {
    height: 100vh;
}

@mixin gradient-font {
    background: linear-gradient(colors.$orange, colors.$orange, colors.$yellow);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.scrollToTop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    width:50px;
    height:50px;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 15px;
    border-radius: sizes.$border-radius;

}

header {
    height: sizes.$image-height;
    background-color: rgba(0, 0, 0, 0.5);
    background-position: top;
    background-size: cover;
    background-blend-mode: darken;
    margin-top: - calc(sizes.$navbar-height + 26px);
    position: relative;

    .container {
        text-align: center;
        padding-top: sizes.$navbar-height;
        padding-bottom: sizes.$large-spacing;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: end;
        letter-spacing: 5px;

        h2 {
            text-transform: uppercase;
            font-size: 1.5rem;
            font-weight: 300;
        }

        h1 {
            font-size: 5rem;
            font-weight: 500;

            @include bootstrap.media-breakpoint-down(lg) {
                font-size: 3rem;
            }

            @include bootstrap.media-breakpoint-down(md) {
                font-size: 2rem;
            }

            .fName {
                @include gradient-font;
            }

            .lName {
                color: colors.$yellow;
            }
        }
    }
}

.row {
    --bs-gutter-x: 10rem;
    align-items: center;
}

@mixin blob {
    content: "";
    background-image: animations.$blob-gradient;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    aspect-ratio: 10 / 8;
    border-radius: 46% 54% 51% 49% / 57% 41% 59% 43%;
    animation: transform 12s infinite;
}


.blob {
    position: relative;
    display: flex;
    align-items: center;
    > * {
        z-index: 1;
    }
    &:after {
        @include blob;
    }
}

.blob-left {
    &:after {
        left: auto;
        right: auto;
        @include bootstrap.media-breakpoint-up(lg) {
            right: sizes.$large-spacing;
        }
    };
    justify-content: start;
}

.blob-right {
    &:after {
        right: auto;
        left: auto;
        @include bootstrap.media-breakpoint-up(lg) {
            left: sizes.$large-spacing;
        }
    }
    justify-content: end;
}

.blob-top {
    &:after {
        top: -25%;
    }
}

.blob-bottom {
    &:after {
        top: 25%;
    }
}

.text {
    padding-top: sizes.$text-padding;
    padding-bottom: sizes.$text-padding;
    .btn {
        background: colors.$orange-dark;
        border: none;
        border-radius: sizes.$border-radius;
        color: white;
    }
}



section {
    display: flex;
    margin-bottom: sizes.$large-spacing;
    margin-top: sizes.$large-spacing;
    .row {
        position: relative;
        .image {
            @media (min-width: 991px) {
                position: sticky;
                top: sizes.$navbar-height;
            }
            padding-top: sizes.$text-padding;
            display: flex;
            img {
                object-fit: cover;
                object-position: top;
                width: auto;
                max-height: sizes.$image-height;
                @include bootstrap.media-breakpoint-down(md) {
                    max-height: sizes.$image-height-md;
                }
                @include bootstrap.media-breakpoint-down(lg) {
                    max-height: sizes.$image-height-lg;
                }
                @include bootstrap.media-breakpoint-down(xl) {
                    max-height: sizes.$image-height-xl;
                }
            }
        }
    }
}

.sponsors {
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 110%;
        height: 110%;
        z-index: -1;
        background-image: url("../assets/img/home_sponsoren_hintergrund.webp");
        background-size: cover;
        background-position: center;
        filter: grayscale(100%) blur(sizes.$blur);
    }
    overflow: hidden;
    padding: sizes.$large-spacing 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .row {
        --bs-gutter-x: 1rem;
        --bs-gutter-y: 1rem;
        .image {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: sizes.$border-radius;
            height: 200px;
            padding: calc(sizes.$text-padding);
            @include bootstrap.media-breakpoint-down(md) {
                padding: calc(sizes.$text-padding  / 3);
            }
            background: rgba($color: #fff, $alpha: 0.7);
            backdrop-filter: blur(sizes.$blur);
            img{
                max-height: 100px;
                object-fit: contain;
                object-position: center;
            }
            &:hover {
                background: rgba($color: #fff, $alpha: 0.9);
                scale: 1.05;
                transition: all 0.2s ease-in-out;
                // cursor: pointer;
            }
            transition: all 0.2s ease-in-out;
        }
    }
}

footer {
    padding-bottom: sizes.$large-spacing;
    .logo {
        height: sizes.$navbar-height;
    }
    .nav-link {
        padding-left: 0;
        padding-right: 0;
    }
}