
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-Italic.ttf");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-Thin.ttf");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-ThinItalic.ttf");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-LightItalic.ttf");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-Medium.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-Black.ttf");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Roboto";
    src: url("/src/assets/fonts/Roboto/Roboto-BlackItalic.ttf");
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}