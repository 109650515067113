@use "abstracts/sizes";
@use "abstracts/colors";

.infopage {
    padding-top: sizes.$text-padding;
    section {
        display: block;
        margin: 0;
        padding: sizes.$text-padding 0 0 0;
    }
}