$navbar-height: 70px;
$nav-item-spacing: 3rem;
$large-spacing: 100px;
$text-padding: 50px;
$image-height: 800px;
$image-height-xl: 500px;
$image-height-lg: 400px;
$image-height-md: 300px;
$border-radius: 1rem;
$small-border-radius: calc($border-radius/4);
$blur: 5px;