@use "colors";
@use "bootstrap";

@keyframes transform {
    0%,
    100% {
        transform: rotate(0deg) perspective(700px) scale(1) skew(3deg, 4deg);
    }

    25% {
        transform: rotate(15deg) perspective(700px) skew(8deg, 6deg) scale(0.99);
    }

    50% {
        transform: rotate(18deg) perspective(700px) scale(1) skew(4deg, 6deg);
    }

    25% {
        transform: rotate(12deg) perspective(700px) scale(0.99) skew(5deg, 4deg);
    }
}
    
$blob-gradient: linear-gradient(to right top, colors.$orange, colors.$yellow);

.hidden {
    opacity: 0;
    transition: all 0.5s;
    &.right, &.left {
        filter: blur(5px);
    }
    &.right {
        transform: translateX(100%);
    }
    &.left {
        transform: translateX(-100%);
    }
}

.show {
    &.right, &.left {
        transform: translateX(0);
        filter: blur(0);
    }
    opacity: 1;
}

@media (prefers-reduced-motion) {
    .hidden {
        transition: none;
    }
}

.hide-on-mobile {
    @include bootstrap.media-breakpoint-down(lg){
        display: none !important;
    }
}

.hide-on-desktop {
    @include bootstrap.media-breakpoint-up(lg){
        display: none !important;
    }
}